<template>
  <app-form-select
    v-model="model"
    class="min-w-[150px]"
    :clearable="clearable"
    :hide-details="hideDetails"
    item-title="label"
    item-value="value"
    :items="[
      { value: true, label: 'Inter-entreprise' },
      { value: false, label: 'Intra-entreprise' },
    ]"
    label="Type"
    :readonly="readonly"
    :return-object="false"
  />
</template>

<script lang="ts" setup>
const model = defineModel<boolean>({ default: undefined });

defineProps<{
  hideDetails?: boolean;
  clearable?: boolean;
  readonly?: boolean;
}>();
</script>
